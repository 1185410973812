import React, { useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusMessage } from '../redux/actions/generalActions';
import logo from './../assets/logo.png';
import greenfeeDealsApi from '../api/greenfeeDeals';

function EmailVerification(props) {
  const { emailVerificationToken } = useParams();
  const statusMessage = useSelector((state) => state.general.statusMessage);
  const dispatch = useDispatch();
  const verifyEmail = async () => {
    dispatch(setStatusMessage(''));
    try {
      const response = await greenfeeDealsApi.post(
        `users/verifyemail/${emailVerificationToken}`
      );
      dispatch(
        setStatusMessage(
          'Vielen Dank, Deine E-Mail-Adresse ist nun verifiziert!'
        )
      );
    } catch (error) {
      dispatch(
        setStatusMessage(
          'Das hat leider nicht geklappt. Hast Du Deine E-Mail-Adresse vielleicht schon bestätigt? Sonst schreibe uns gerne eine kurze Nachricht an support@greenfee-deals.de und wir bringen das in Ordnung!'
        )
      );
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: '#293642',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      <Row>
        <Col span={24} justify="center" align="center">
          <img
            src={logo}
            alt="Logo"
            width="100%"
            style={{
              marginTop: '100px',
              marginBottom: '40px',
              maxWidth: '250px',
            }}
          />
          <h3
            style={{
              color: 'white',
              fontSize: 18,
              fontWeight: 'Normal',
              textAlign: 'center',
              marginTop: 50,
              maxWidth: 500,
            }}
          >
            {statusMessage}
          </h3>
        </Col>
      </Row>
    </Layout>
  );
}

export default EmailVerification;
