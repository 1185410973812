/* eslint-disable */

import React, { useEffect, useState } from 'react';
import SiderComponent from './../components/SiderComponent';
import HeaderComponent from './../components/HeaderComponent';
import EditCoursesComponent from './../components/EditCoursesComponent';
import TitlePicturePlaceholder from './../assets/TitlePicturePlaceholder.jpg';
import config from '../config';
import { useSelector, useDispatch } from 'react-redux';
import { setGolfClubDetails } from '../redux/actions/golfClubActions';

import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  Checkbox,
  Upload,
  Alert,
  notification,
} from 'antd';
import greenfeeDealsApi from '../api/greenfeeDeals';

const { Content } = Layout;

function Details(props) {
  const sessionBnd = localStorage.getItem('sessionBnd');
  const headers = {
    headers: { Authorization: `Bearer ${sessionBnd}` },
  };
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );
  const userDetails = useSelector((state) => state.general.userDetails);
  const userRole = userDetails.role;
  const alliances = useSelector((state) => state.general.alliances);
  let alliancesIds = [];
  let AllianceValues = [];

  alliances.forEach((e) => {
    alliancesIds.push(e._id);
  });

  alliancesIds.forEach((e) => {
    if (golfClubDetails.alliances && golfClubDetails.alliances.includes(e)) {
      AllianceValues.push(true);
    } else {
      AllianceValues.push(false);
    }
  });

  if (!golfClubDetails) {
    props.history.push(`/`);
    return <div></div>;
  }
  const updateGolfClub = async (values) => {
    if (!values.golfClubValues) {
      values.golfClubValues = {};
    }
    values.golfClubValues.alliances = [];
    alliancesIds.forEach((e, i) => {
      if (values.AllianceValues?.[i] === true) {
        values.golfClubValues.alliances.push(e);
      }
    });

    // Update GolfClub
    await greenfeeDealsApi.patch(
      `/golfclubs/${golfClubId}`,
      values.golfClubValues,
      headers
    );

    // Get Current Values from Server
    const golfClub = await greenfeeDealsApi.post(
      `/golfclubs/golfClubAdmin`,
      { id: golfClubId },
      headers
    );
    if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
      console.log('Error no Golfclub found for your Admin Account');
      return;
    }
    const results = golfClub.data.data.data;

    // Update Redux Store
    dispatch(setGolfClubDetails(results));

    notification.success({
      message: 'Update Successfully',
      description: 'The golf club details have been updated successfully.',
    });
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const uploadImageData = new FormData();
    uploadImageData.append('imageCover', file);
    try {
      const golfClubImage = await greenfeeDealsApi.patch(
        `/golfclubs/${golfClubId}`,
        uploadImageData,
        headers
      );
      // Get Current Values from Server
      const golfClub = await greenfeeDealsApi.post(
        `/golfclubs/golfClubAdmin`,
        { id: golfClubId },
        headers
      );
      if (golfClub.data.status !== 'success' || golfClub.data.results === 0) {
        console.log('Error no Golfclub found for your Admin Account');
        return;
      }
      const results = golfClub.data.data.data;

      // Update Redux Store
      dispatch(setGolfClubDetails(results));
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [golfClubDetails]);

  const golfClubId = golfClubDetails._id.golfClub;

  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <SiderComponent props={props} />
        <Layout>
          <HeaderComponent />
          <Content
            style={{
              backgroundColor: '#EAEAEA',
              paddingLeft: 50,
              paddingRight: 50,
              paddingBottom: 50,
            }}
          >
            <h3 style={{ marginTop: 30 }}>Stammdaten</h3>

            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 15,
                width: '100%',
                padding: 30,
                marginTop: 20,
              }}
            >
              <Form
                form={form}
                onFinish={updateGolfClub}
                hideRequiredMark
                initialValues={{
                  golfClubValues: golfClubDetails,
                  AllianceValues: AllianceValues,
                }}
              >
                <Row>
                  <Col span={15} style={{ paddingLeft: 5, paddingRight: 50 }}>
                    <Row>
                      <Upload
                        name="avatar"
                        showUploadList={false}
                        customRequest={uploadImage}
                      >
                        {golfClubDetails.imageCover ? (
                          <img
                            src={`https://greenfeedeals2.s3.eu-central-1.amazonaws.com/${golfClubDetails.imageCover}`}
                            alt="avatar"
                            style={{ width: '100%', cursor: 'pointer' }}
                          />
                        ) : (
                          <img
                            src={TitlePicturePlaceholder}
                            alt="avatar"
                            style={{ width: '100%', cursor: 'pointer' }}
                          />
                        )}
                              
                      </Upload>
                      <Alert
                        message="Neues Bild einfach per Drag & Drop auf bestehendes Bild ziehen. Maße: 1485x683px_max. 1MB Text"
                        type="info"
                      />
                    </Row>
                  </Col>
                  <Col span={9} style={{ paddingRight: 30 }}>
                    <Row style={{ paddingBottom: 15 }}>
                      <h5>Name</h5>
                      <Form.Item
                        name={['golfClubValues', 'name']}
                        style={{ width: 400 }}
                      >
                        <Input size="big" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <h5>Kontakt</h5>
                      <Form.Item
                        name={['golfClubValues', 'address', 'street']}
                        style={{ width: '100%' }}
                      >
                        <Input
                          size="small"
                          placeholder="Strasse & Hausnummer"
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'address', 'zip']}
                        style={{ width: '30%' }}
                      >
                        <Input
                          size="small"
                          style={{ width: '100%' }}
                          placeholder="PLZ"
                          type="text" // Treat the input as a string
                        />
                      </Form.Item>
                      <Form.Item
                        name={['golfClubValues', 'address', 'city']}
                        style={{ width: '70%' }}
                      >
                        <Input size="small" placeholder="Ort" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'phone']}
                        style={{ width: '100%' }}
                      >
                        <Input size="small" placeholder="Telefonnummer" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'email']}
                        style={{ width: '100%' }}
                      >
                        <Input size="small" placeholder="E-Mail" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'url']}
                        style={{ width: '100%' }}
                      >
                        <Input size="small" placeholder="Webadresse" />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'startTimeBookingUrl']}
                        style={{ width: '100%' }}
                      >
                        <Input size="small" placeholder="Booking URL" />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Row style={{ width: '102%' }}>
                <Col
                  span={24}
                  className="standardCol"
                  style={{ paddingBottom: 0 }}
                >
                  <h3 style={{ marginTop: 30 }}>Plätze und Greenfee</h3>
                  {golfClubDetails.courses.map((item) => {
                    return (
                      <EditCoursesComponent course={item} key={item[0]._id} />
                    );
                  })}
                </Col>
              </Row>

              <Form
                form={form}
                onFinish={updateGolfClub}
                hideRequiredMark
                initialValues={{
                  golfClubValues: golfClubDetails,
                  AllianceValues: AllianceValues,
                }}
              >
                <Row style={{ marginTop: 40 }}>
                  <Col
                    span={24}
                    className="standardCol"
                    style={{ paddingBottom: 0 }}
                  >
                    <h3>Preise gelten für eine 18-Loch-Runde</h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    span={9}
                    className="standardCol"
                    style={{ paddingRight: 160 }}
                  >
                    <h5>Driving Range</h5>
                    <h6>Abschlagplätze</h6>
                    <Form.Item
                      name={['golfClubValues', 'drivingrange', 'ranges']}
                    >
                      <Select size="small">
                        <Option value="Rasen">Rasen</Option>
                        <Option value="Matten">Matten</Option>
                        <Option value="Rasen & Matten">Rasen & Matten</Option>
                        <Option value="keine">keine</Option>
                      </Select>
                    </Form.Item>
                    <h6>Überdachung</h6>
                    <Form.Item
                      name={['golfClubValues', 'drivingrange', 'covered']}
                    >
                      <Select size="small">
                        <Option value="ja">ja</Option>
                        <Option value="teilweise">teilweise</Option>
                        <Option value="nein">nein</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={['golfClubValues', 'drivingrange', 'pitchAreas']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>
                          Pitch- und Chip-Area
                        </h6>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={['golfClubValues', 'drivingrange', 'puttingGreen']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>Putting Green</h6>
                      </Checkbox>
                    </Form.Item>
                    <h6 style={{ width: '100%', marginTop: 20 }}>Token</h6>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'drivingrange', 'balls']}
                      >
                        <InputNumber size="small" style={{ width: 40 }} />
                      </Form.Item>
                      <h6
                        style={{
                          display: 'inline',
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        Bälle für
                      </h6>
                      <Form.Item
                        name={['golfClubValues', 'drivingrange', 'token']}
                        size="small"
                      >
                        <InputNumber
                          size="small"
                          formatter={(value) => `${value} €`}
                          style={{ width: 40 }}
                        />
                      </Form.Item>
                    </Row>
                    <h6 style={{ width: '100%', marginTop: 20 }}>Rangefee</h6>
                    <Row>
                      <Form.Item
                        name={['golfClubValues', 'drivingrange', 'rangefee']}
                        size="small"
                      >
                        <InputNumber
                          size="small"
                          formatter={(value) => `${value} €`}
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={7} className="standardCol">
                    <h5>Verleih</h5>
                    <h6>E-Cart</h6>
                    <Form.Item
                      name={['golfClubValues', 'rentals', 'eCart']}
                      size="small"
                    >
                      <InputNumber
                        size="small"
                        formatter={(value) => `${value} €`}
                        style={{ width: 150 }}
                      />
                    </Form.Item>

                    <h6>E-Trolley</h6>
                    <Form.Item
                      name={['golfClubValues', 'rentals', 'eTrolley']}
                      size="small"
                    >
                      <InputNumber
                        size="small"
                        formatter={(value) => `${value} €`}
                        style={{ width: 150 }}
                      />
                    </Form.Item>

                    <h6>Trolley</h6>
                    <Form.Item
                      name={['golfClubValues', 'rentals', 'trolley']}
                      size="small"
                    >
                      <InputNumber
                        size="small"
                        formatter={(value) => `${value} €`}
                        style={{ width: 150 }}
                      />
                    </Form.Item>
                    <h6>Halbsatz</h6>
                    <Form.Item
                      name={['golfClubValues', 'rentals', 'semiSet']}
                      size="small"
                    >
                      <InputNumber
                        size="small"
                        formatter={(value) => `${value} €`}
                        style={{ width: 150 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="standardCol">
                    <h5>Services</h5>
                    <Form.Item
                      name={[
                        'golfClubValues',
                        'services',
                        'startTimeReservation',
                      ]}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>
                          Startzeitenreservierung erforderlich
                        </h6>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={['golfClubValues', 'services', 'proShop']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>Pro-Shop</h6>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={['golfClubValues', 'services', 'restaurant']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>Gastronomie</h6>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={['golfClubValues', 'services', 'guestLockers']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>Gästesanitär</h6>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name={['golfClubValues', 'services', 'dogsOk']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>Hunde erlaubt</h6>
                      </Checkbox>
                    </Form.Item>
                    {/* <Form.Item
                      name={['golfClubValues', 'services', 'roundProvisioning']}
                      valuePropName="checked"
                      style={{ paddingTop: 10 }}
                    >
                      <Checkbox>
                        <h6 style={{ display: 'inline' }}>Rundenverpflegung</h6>
                      </Checkbox>
                    </Form.Item> */}
                    <h6>RPR-Runde</h6>
                    <Form.Item
                      name={['golfClubValues', 'services', 'edsRound']}
                      size="small"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: 150 }}
                        formatter={(value) => `${value} €`}
                      />
                    </Form.Item>
                    <h6>Birdiebook</h6>
                    <Form.Item
                      label="Preis für haptisches Birdiebook:"
                      name={['golfClubValues', 'services', 'birdieBook']}
                      size="small"
                    >
                      <InputNumber
                        size="small"
                        style={{ width: 100 }}
                        formatter={(value) => `${value} €`}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Link für digitales Birdiebook:"
                      name={['golfClubValues', 'services', 'birdieBookUrl']}
                      size="small"
                      rules={[
                        {
                          pattern:
                            /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}.*$/,
                          message: 'Please enter a valid URL',
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        style={{ width: 300 }}
                        placeholder="Enter URL"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ width: '100%' }} justify="end">
                  {(userRole === 'admin' || userRole === 'golfClubAdmin') && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginTop: 60,

                        marginBottom: 30,
                        marginRight: 30,
                        minWidth: 85,
                        minHeight: 40,
                      }}
                    >
                      Speichern
                    </Button>
                  )}
                </Row>
              </Form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Details;
