const initialState = {
  menuCollapsed: false,
  subMenuCollapsed: [],
  adminSearchResults: '',
  treeDataNewDeal: ['hi'],
};

const generalReducer = (state = initialState, action) => {
  const {
    menuCollapsed,
    subMenuCollapsed,
    adminSearchTerm,
    adminSearchResults,
    membershipSearchResults,
    discountCards,
    membershipTypes,
    alliances,
    membershipData,
    treeDataNewDeal,
    userDetails,
    statusMessage,
  } = action;
  switch (action.type) {
    case 'SET_MENU_COLLAPSED':
      return { ...state, menuCollapsed };
    case 'SET_SUBMENU_COLLAPSED':
      return { ...state, subMenuCollapsed };
    case 'SET_ADMINSEARCH_TERM':
      return { ...state, adminSearchTerm };
    case 'SET_ADMINSEARCH_RESULTS':
      return { ...state, adminSearchResults };
    case 'SET_MEMBERSHIPSEARCH_RESULTS':
      return { ...state, membershipSearchResults };
    case 'SET_DISCOUNTCARDS':
      return { ...state, discountCards };
    case 'SET_MEMBERSHIP_TYPES':
      return { ...state, membershipTypes };
    case 'SET_ALLIANCES':
      return { ...state, alliances };
    case 'SET_MEMBERSHIP_DATA':
      return { ...state, membershipData };
    case 'SET_TREEDATA_NEW_DEAL':
      return { ...state, treeDataNewDeal };
    case 'SET_USER_DETAILS':
      return { ...state, userDetails };
    case 'SET_STATUS_MESSAGE':
      return { ...state, statusMessage };

    default:
      return state;
  }
};

export default generalReducer;
