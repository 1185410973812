import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setMenuCollapsed } from '../redux/actions/generalActions';
import { setSubMenuCollapsed } from '../redux/actions/generalActions';
import { Layout, Menu, Affix } from 'antd';
import { DesktopOutlined, MailOutlined } from '@ant-design/icons';
import logo from './../assets/logo.png';
import logoCondensed from './../assets/logoCondensed.png';
const { Sider } = Layout;
const _ = require('lodash');

function SiderComponent({ props }) {
  const golfClubDetails = useSelector(
    (state) => state.golfClub.golfClubDetails
  );
  const subMenuCollapsed = useSelector(
    (state) => state.general.subMenuCollapsed
  );
  const numberGreenfeeabkommenDeals = golfClubDetails.membershipDeals.length;
  const numberTopDeals = golfClubDetails.topDeals
    ? golfClubDetails.topDeals.length
    : 0;
  const numberEduDeals = _.countBy(golfClubDetails.deals, function (e) {
    return e.dealDetails.type === 'edu';
  });
  const numberMemberDeals = _.countBy(golfClubDetails.deals, function (e) {
    return e.name === 'Member Guest' && e.dealDetails.type === 'special';
  });
  const numberDiscountcardDeals = _.countBy(
    golfClubDetails.deals,
    function (e) {
      return e.dealDetails.type === 'discountCard';
    }
  );
  const numberSurchargeDeals = _.countBy(golfClubDetails.deals, function (e) {
    return e.dealDetails.type === 'surcharge';
  });
  const numberAllianceDeals = _.countBy(golfClubDetails.deals, function (e) {
    return e.dealDetails.type === 'alliance';
  });
  const numberEarlyBirdDeals = _.countBy(golfClubDetails.deals, function (e) {
    return (
      (e.name === 'Early Bird' || e.name === 'Sundowner') &&
      e.dealDetails.type === 'special'
    );
  });
  const numberSpecialDeals = _.countBy(golfClubDetails.deals, function (e) {
    return (
      e.name !== 'Early Bird' &&
      e.name !== 'Sundowner' &&
      e.name !== 'Member Guest' &&
      e.dealDetails.type === 'special'
    );
  });

  const { SubMenu } = Menu;
  const dispatch = useDispatch();
  const menuCollapsed = useSelector((state) => state.general.menuCollapsed);
  const toggleCollapsed = () => {
    const collapsed = !menuCollapsed;
    dispatch(setMenuCollapsed(collapsed));
  };
  const toggleSubCollapsed = (openKeys) => {
    dispatch(setSubMenuCollapsed(openKeys));
  };
  const Showlogo = () => {
    if (!menuCollapsed) {
      return <img src={logo} alt="Logo" width={'80%'} />;
    } else {
      return <img src={logoCondensed} alt="Logo" width={30} />;
    }
  };
  return (
    <div
      style={{
        backgroundColor: '#293642',
        minHeight: '100%',
      }}
    >
      <Affix offsetTop={0}>
        <Sider
          style={{
            backgroundColor: '#293642',
            minHeight: '100%',
          }}
          collapsible
          collapsed={menuCollapsed}
          onCollapse={toggleCollapsed}
          trigger={null}
          width={255}
        >
          <div
            style={{
              minHeight: 150,
              justifyContent: 'flex-start',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: 15,
            }}
          >
            <Showlogo />
          </div>

          <Menu
            defaultSelectedKeys={['1']}
            mode="inline"
            theme="dark"
            style={{ backgroundColor: '#293642' }}
            selectedKeys={location.pathname}
            activeKey={location.pathname}
            openKeys={subMenuCollapsed}
            onOpenChange={toggleSubCollapsed}
            inlineIndent={20}
          >
            <Menu.Item key="/details">
              <DesktopOutlined />
              <span>Stammdaten</span>
              <Link to="/details" />
            </Menu.Item>

            <SubMenu
              key="sub1"
              title={
                <span>
                  <MailOutlined />
                  <span>Deals</span>
                </span>
              }
            >
              <Menu.Item key="/deals/topdeals">
                <DesktopOutlined />
                <span>
                  Top Deals ({numberTopDeals > 0 ? numberTopDeals : 0})
                </span>
                <Link to="/deals/topdeals" />
              </Menu.Item>
              <Menu.Item key="/deals/edu">
                <DesktopOutlined />
                <span>
                  Studenten & Jugend (
                  {numberEduDeals.true > 0 ? numberEduDeals.true : 0})
                </span>
                <Link to="/deals/edu" />
              </Menu.Item>
              {
                <Menu.Item key="/deals/earlybird">
                  <DesktopOutlined />
                  <span>
                    Early Bird & Sundowner (
                    {numberEarlyBirdDeals.true > 0
                      ? numberEarlyBirdDeals.true
                      : 0}
                    )
                  </span>
                  <Link to="/deals/earlybird" />
                </Menu.Item>
              }
              <Menu.Item key="/deals/greenfeeabkommen">
                <DesktopOutlined />
                <span>Greenfeeabkommen ({numberGreenfeeabkommenDeals})</span>
                <Link to="/deals/greenfeeabkommen" />
              </Menu.Item>
              <Menu.Item key="/deals/greenfeecards">
                <DesktopOutlined />
                <span>
                  Rabattkarten (
                  {numberDiscountcardDeals.true > 0
                    ? numberDiscountcardDeals.true
                    : 0}
                  )
                </span>
                <Link to="/deals/greenfeecards" />
              </Menu.Item>

              <Menu.Item key="/deals/clubverbuende">
                <DesktopOutlined />
                <span>
                  Clubverbünde (
                  {numberAllianceDeals.true > 0 ? numberAllianceDeals.true : 0})
                </span>
                <Link to="/deals/clubverbuende" />
              </Menu.Item>

              <Menu.Item key="/deals/sonstige">
                <DesktopOutlined />
                <span>
                  Sonstige (
                  {numberSpecialDeals.true > 0 ? numberSpecialDeals.true : 0})
                </span>
                <Link to="/deals/sonstige" />
              </Menu.Item>
              <Menu.Item key="/deals/memberdeal">
                <DesktopOutlined />
                <span>
                  Member Guest (
                  {numberMemberDeals.true > 0 ? numberMemberDeals.true : 0})
                </span>
                <Link to="/deals/memberdeal" />
              </Menu.Item>
              <Menu.Item key="/deals/aufschlaege">
                <DesktopOutlined />
                <span>
                  VcG & Fernmitglied (
                  {numberSurchargeDeals.true > 0
                    ? numberSurchargeDeals.true
                    : 0}
                  )
                </span>
                <Link to="/deals/aufschlaege" />
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
      </Affix>
    </div>
  );
}

export default SiderComponent;
